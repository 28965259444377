<template>
  <div class="text-right">
    <v-dialog v-model="dialog" max-width="500" persistent>
      <template v-slot:activator="{ on }">
        <v-btn :disabled="user.id !== 1" text v-on="on">
          <v-icon left>mdi-plus</v-icon>
          <span>Добавить</span>
        </v-btn>
      </template>
      <v-card class="project-create-card px-4" outlined>
        <v-form ref="projectForm" @submit.prevent="checkInput">
          <v-card-title>Добавить проект</v-card-title>
          <v-card-text>
            <span class="font-weight-medium">Название</span>
            <v-text-field v-model="project.title" :rules="titleRules" hide-details="auto" class="mt-1 mb-3" single-line outlined dense />
            <span class="font-weight-medium">Описание</span>
            <v-textarea v-model="project.description" class="mt-1" hide-details outlined dense />
          </v-card-text>
          <v-card-actions class="mb-2">
            <v-spacer></v-spacer>
            <v-btn :loading="loading" type="submit" class="text-capitalize" color="primary" depressed> Добавить </v-btn>
            <v-btn @click="closeDialog" class="mx-2 text-none" color="grey darken-3" text>Отмена</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      loading: false,
      project: this.createProjectStub(),
      titleRules: [(v) => !!v || 'Обязательное поле']
    }
  },
  methods: {
    createProjectStub() {
      return {
        title: '',
        description: ''
      }
    },
    checkInput() {
      if (this.$refs.projectForm.validate()) {
        this.loading = true
        this.submitData()
      }
    },
    submitData() {
      this.createProject(this.project)
        .then(() => {
          this.loading = false
          this.closeDialog()
        })
        .catch(() => {
          this.loading = false
        })
    },
    closeDialog() {
      this.dialog = false
      this.$refs.projectForm.reset()
      this.project = this.createProjectStub()
    },
    ...mapActions('dashboard', ['createProject'])
  }
}
</script>
<style scoped>
.project-create-card .v-btn {
  font-weight: 400;
}
</style>
