<template>
  <div class="projects">
    <h1 class="subtitle-1 grey--text">Все проекты</h1>

    <ProjectDialogCreate :user="user" />

    <v-row>
      <v-col v-if="isLoading"> Загрузка проектов...</v-col>
      <v-col v-else-if="projects.length === 0"> Проектов пока нет </v-col>
      <v-col cols="12" md="4" v-for="project in projects" :key="project.id">
        <ProjectCard :project="project" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ProjectDialogCreate from '@/components/project/ProjectDialogCreate'
import ProjectCard from '@/components/project/ProjectCard'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    ProjectDialogCreate,
    ProjectCard
  },
  computed: {
    ...mapGetters({
      isLoading: 'dashboard/isLoading',
      projects: 'dashboard/projects',
      user: 'auth/user'
    })
  },
  mounted() {
    this.fetchProjects()
  },
  methods: {
    ...mapActions('dashboard', ['fetchProjects'])
  }
}
</script>
