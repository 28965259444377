<template>
  <v-card outlined>
    <v-list-item three-line>
      <v-list-item-content>
        <div class="overline mb-4">
          {{ project.updatedAt | moment('YYYY-MM-DD HH:mm') }}
        </div>
        <v-list-item-title class="headline text-wrap mb-1">{{ project.title }}</v-list-item-title>
        <v-list-item-subtitle>{{ project.description }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-card-actions>
      <v-row class="px-2">
        <v-btn v-if="![2, 5].includes(project.id)" text small :to="{ name: 'project-kits', params: { projectId: project.id } }">Комплекты </v-btn>
        <v-btn v-if="project.id === 1" text small :to="{ name: 'horse-rides', params: { projectId: project.id } }">Конные прогулки </v-btn>
        <v-btn v-if="[1, 4].includes(project.id)" text small :to="{ name: 'project-photo-coupes', params: { projectId: project.id } }">Фото-купе </v-btn>
        <v-btn v-if="[1, 4].includes(project.id)" text small :to="{ name: 'project-sales', params: { projectId: project.id } }">Продажи </v-btn>
        <v-btn v-if="[1, 4].includes(project.id)" text small :to="{ name: 'project-statistic', params: { projectId: project.id } }">Статистика продаж </v-btn>
        <v-btn v-if="[2, 5].includes(project.id)" text small :to="{ name: 'products', params: { projectId: project.id } }">Сувениры </v-btn>
        <v-btn v-if="[2, 5].includes(project.id)" text small :to="{ name: 'remains', params: { projectId: project.id } }">Остатки </v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    project: {
      type: Object,
      required: true
    }
  }
}
</script>
